/**
 * cFormTable
 * @author chensi
 */

@import "../../common/sassMixin";

.c-form-table {
    width: 100%;
    .c-form-table-header {
        width: 100%;
        height: 32px;
        border-bottom: 1px solid #d9d9d9;
        .c-form-table-title {
            float: left;
            height: 32px;
            padding: 0 12px;
            margin-right: 22px;
            font-size: 16px;
            text-align: center;
            line-height: 32px;
            border-top: 1px solid #d9d9d9;
            border-right: 1px solid #d9d9d9;
            border-left: 1px solid #d9d9d9;
            border-bottom: 1px solid #fff;
            border-top-left-radius: 2px;
            border-top-right-radius: 2px;
        }
        .c-form-table-btns {
            position: relative;
            z-index: 2;
            float: left;
            max-width: 100%;
            height: 32px;
            line-height: 30px;
            white-space: nowrap;
            overflow: hidden;
            .ant-btn {
                margin-left: 8px;
                .anticon {
                    width: 1em;
                    height: 1em;
                    &.c-icon-row-add-new,
                    &.c-icon-row-insert {
                        color: $colorSuccess;
                    }
                    &.c-icon-row-remove,
                    &.c-icon-row-clear-all {
                        color: $colorDanger;
                    }
                    &.c-icon-row-copy {
                        color: $colorWarning;
                    }
                }
                .anticon+span {
                    margin-left: 4px;
                }
            }
        }
    }
    .c-rich-table {
        border-right: 1px solid #d9d9d9;
        border-left: 1px solid #d9d9d9;
        border-bottom: 1px solid #d9d9d9;
        .table-row-selected {
            background-color: #c2dffd;
        }
        .ant-table-row {
            height: 32px;
        }
        .ant-table .ant-table-row:hover>td {
            background-color: #ffe8b6;
        }
        .ant-table .table-row-selected:hover>td {
            background-color: #c2dffd;
        }
    }
}