/**
 * cExportModal
 * @author chensi
 */

@import "../../common/sassMixin";

.not-show {
    display: none !important;
}
.export-progress {
    min-height: 300px;
    padding-top: 40px;
    .icon {
        text-align: center;
        img {
            width: 60px;
        }
        .anticon-check {
            width: 60px;
            height: 60px;
            font-size: 40px;
            color: #fff;
            background-color: #68c639;
            border-radius: 50%;
            svg {
                display: block;
                margin: 10px auto 0;
            }
        }
    }
    .title {
        font-size: 24px;
        text-align: center;
        margin-top: 15px;
    }
    .progress {
        width: 96%;
        padding-top: 10px;
        margin: 0 auto;
    }
}

.before-start {
    min-height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
    .before-start-btn {
        padding: 4px 30px;
        margin: 0px 5px;
    }
}
.modal-footer-close {
    position: absolute;
    left: 0px;
    width: 100%;
    height: 60px;
    bottom: 0px;
    border-top: 1px solid #f0f0f0;
    text-align: center;
    line-height: 60px;
    .modal-footer-btn {
        padding: 4px 30px;
        margin: 0px 5px;
    }
}